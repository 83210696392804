.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background: white;
    padding-bottom: 10%;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 0;
        gap: 2rem;
    }
}

.images-container {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    gap: 1rem;
    padding: 2rem;
    height: 100vh;

    @media (max-width: 768px) {
        display: none;
    }
}

.column {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 12px;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    &:first-child img {
        animation: slideDown 25s linear infinite;
    }

    &:last-child img {
        animation: slideUp 25s linear infinite;
    }

    &:hover img {
        animation-play-state: paused;
    }
}

.content-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 4rem;
    background: white;
    margin-top: 20vh;

    @media (max-width: 768px) {
        padding: 2rem;
        margin-top: 1rem;
    }
}

.title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;

    span {
        color: #00a9a7;
    }

    @media (max-width: 768px) {
        margin-top: 4rem;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 0.75rem;
    }
}

.subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        font-size: 1rem;
        margin-bottom: 1.5rem;
        line-height: 1.5;
    }
}

.search-container {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 4rem);
        padding: 0;
        margin: 0;
    }
}

.mobile-images-strip-container {
    display: none;

    @media (max-width: 768px) {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        overflow: hidden;
    }
}

.mobile-images-strip {
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 0;
    animation: slideLeft 20s linear infinite;

    img {
        width: 200px;
        height: 150px;
        object-fit: cover;
        border-radius: 12px;
        flex-shrink: 0;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(100%);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(-100%);
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

