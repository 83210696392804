.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    border-bottom: 1px solid #eaeaea;
    z-index: 1000;

    &__container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        color: inherit;

        &-image {
            width: 32px;
            height: 32px;
        }

        &-text {
            font-size: 1.25rem;
            font-weight: 600;
        }
    }

    &__desktop-menu {
        display: none;
        align-items: center;
        gap: 2rem;

        @media (min-width: 1024px) {
            display: flex;
        }
    }

    &__link {
        text-decoration: none;
        color: #4b5563;
        font-size: 0.875rem;
        transition: color 0.2s;

        &:hover {
            color: #000;
        }
    }

    &__auth {
        position: relative;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 0.5rem;
        background: white;
        border: 1px solid #eaeaea;
        border-radius: 0.5rem;
        padding: 1rem;
        min-width: 200px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

        &-toggle {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 0.875rem;
            color: #4b5563;

            &:hover {
                color: #000;
            }
        }

        &-arrow {
            font-size: 0.75rem;
            transition: transform 0.2s;

            &.open {
                transform: rotate(180deg);
            }
        }

        &-section {
            &+& {
                margin-top: 1rem;
                padding-top: 1rem;
                border-top: 1px solid #eaeaea;
            }
        }

        &-label {
            display: block;
            font-size: 0.875rem;
            font-weight: 500;
            color: #4b5563;
            margin-bottom: 0.5rem;
        }

        &-links {
            display: flex;
            gap: 1rem;

            a {
                color: #2563eb;
                text-decoration: none;
                font-size: 0.875rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__mobile-toggle {
        display: block;
        padding: 0.5rem;
        background: none;
        border: none;
        cursor: pointer;

        @media (min-width: 1024px) {
            display: none;
        }
    }

    &__hamburger {
        width: 24px;
        height: 18px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
            display: block;
            width: 100%;
            height: 2px;
            background: #000;
            transition: transform 0.2s;
        }
    }

    &__mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: white;
        padding: 1rem;
        overflow-y: auto;
        z-index: 1001;
    }

    &__mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__close-button {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        padding: 1rem 3rem;
        cursor: pointer;
    }

    &__mobile-auth {
        margin-bottom: 2rem;
        padding: 1rem 0;
        border-bottom: 1px solid #eaeaea;

        &-section {
            &+& {
                margin-top: 1rem;
            }
        }

        &-label {
            display: block;
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }

        &-links {
            display: flex;
            gap: 1rem;

            a {
                color: #2563eb;
                text-decoration: none;
                font-size: 0.875rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__mobile-links {
        display: flex;
        flex-direction: column;
    }

    &__mobile-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        text-decoration: none;
        color: #000;
        font-size: 1rem;
        border-bottom: 1px solid #eaeaea;

        &:last-child {
            border-bottom: none;
        }
    }

    &__mobile-arrow {
        color: #656971;
        margin-right: 2rem;
    }
}
