.lifestyle-medicine {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;

    .title {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
        text-align: start;
        margin-left: 10px;
    }

    .tabs-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 10px;

        .tabs {
            display: flex;
            overflow-x: auto;
            flex-grow: 1;
            justify-content: flex-start;
            margin-right: 20px;

            .tab {
                padding: 10px 20px;
                margin-right: 15px;
                background: none;
                border: 1px solid #ccc;
                border-radius: 12px;
                cursor: pointer;
                color: #666;
                transition: background-color 0.3s, color 0.3s;

                &.active {
                    background-color: #000;
                    color: #fff;
                    border: 1px solid #000;
                }
            }
        }

        .desktop-navigation {
            display: flex;
            gap: 10px;
            flex-shrink: 0;

                        .nav-button {
                            background: white;
                            border: 2px solid #ccc;
                            border-radius: 50%;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 24px;
                            color: #333;
                            transition: background-color 0.3s, border-color 0.3s;
            
                            &:hover {
                                background-color: #f0f0f0;
                                border-color: #999;
                            }
            
                            &.left {
                                transform: rotate(180deg);
                            }
                        }
        }
    }

    .carousel-container {
        position: relative;
        overflow: hidden;

        .carousel {
            display: flex;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: 15px;
            padding: 10px;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }

            .card {
                flex: 0 0 calc(100% / 2.3);
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s, opacity 0.3s;
                opacity: 0.6;
                scroll-snap-align: center;

                &.active {
                    transform: scale(1);
                    opacity: 1;
                }

                .card-image-container {
                    position: relative;

                    .card-image {
                        width: 100%;
                        height: auto;
                        display: block;
                    }

                    .card-metric {
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        background: rgba(255, 255, 255, 0.9);
                        padding: 5px 10px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;

                        .icon {
                            font-size: 16px;
                            margin-right: 5px;
                        }

                        .metric {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }

                .card-content {
                    padding: 15px;
                    background: #fff;

                    .card-title {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }

                    .card-description {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .tabs-container {
            display: none;
        }

        .carousel-container {
            height: auto;

            .carousel {
                .card {
                    flex: 0 0 calc(100% / 1.2);
                    display: flex;
                    flex-direction: column;

                    .card-image-container {
                        height: 300px;

                        .card-image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .card-content {
                        flex-grow: 1;
                        background: #fff;
                        width: 100%;
                        box-sizing: border-box;

                        .card-title {
                            font-size: 16px;
                            margin-bottom: 5px;
                        }

                        .card-description {
                            font-size: 12px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}