.search-container {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 30%;
}

.search-wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 1rem;
    padding: 0.5rem;
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
        align-items: center;
    }
}

.search-field {
    position: relative;
    flex: 1;
    min-width: 100%;

    @media (min-width: 768px) {
        min-width: 0;
    }

    .search-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.search-input {
    width: 80%;
    height: 44px;
    padding: 0.5rem 1rem 0.5rem 3rem;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 0.875rem;
    color: #111827;
    transition: all 0.2s ease;

    &::placeholder {
        color: #6b7280;
    }

    &:focus {
        outline: none;
        border-color: #60a5fa;
        background: #fff;
        box-shadow: 0 0 0 3px rgba(96, 165, 250, 0.1);
    }
}

.search-button {
    flex: 1;
    height: 44px;
    padding: 0 1.5rem;
    border: none;
    border-radius: 8px;
    background: linear-gradient(to right, #4fb8b0, #4f86f7);
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(79, 184, 176, 0.4);
    }

    @media (min-width: 768px) {
        flex: 1;
        max-width: 180px;
    }
}

.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow:hidden;

}

.decorative-strip {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110%;
    height: 40px;
    background: linear-gradient(to right, #FFD1FF, #FAD0C4, #FF9A9E);
    z-index: 1;
    transform: rotate(-5deg);
    transform-origin: left bottom;

    @media (max-width: 768px) {
            display: none;
        }
}

